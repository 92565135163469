/* Margins */
.m-all {
    margin: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-40 {
    margin-left: 40px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-40 {
    margin-right: 40px;
}

.mh-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.mh-20 {
    margin-left: 20px;
    margin-right: 20px;
}

.mh-30 {
    margin-left: 30px;
    margin-right: 30px;
}

.mh-40 {
    margin-left: 40px;
    margin-right: 40px;
}

.mv-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mv-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mv-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.mv-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}


/* Padding */
.p-all {
    padding: 10px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-40 {
    padding-top: 40px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-30 {
    padding-left: 30px;
}

.pl-40 {
    padding-left: 40px;
}

.pr-10 {
    padding-right: 10px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-30 {
    padding-right: 30px;
}

.pr-40 {
    padding-right: 40px;
}

.ph-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.ph-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.ph-30 {
    padding-left: 30px;
    padding-right: 30px;
}


.ph-40 {
    padding-left: 40px;
    padding-right: 40px;
}

.pv-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.pv-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.pv-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.pv-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

/* Gap */
.gap-10 {
    gap: 10px;
}

.gap-20 {
    gap: 20px;
}

.gap-30 {
    gap: 30px;
}

.gap-40 {
    gap: 40px;
}

/* Text */
.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.b {
    font-weight: bold;
}

.b-200 {
    font-weight: 200;
}

.b-300 {
    font-weight: 300;
}

.b-400 {
    font-weight: 400;
}

.b-500 {
    font-weight: 500;
}

.b-600 {
    font-weight: 600;
}

.b-700 {
    font-weight: 700;
}

.b-800 {
    font-weight: 800;
}

.b-900 {
    font-weight: 900;
}

/* Width */
.w-100 {
    width: 100%;
}

/* Font */
.header-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
}

.subheader-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
}

/* Card */
.card {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-bottom: 2px solid #ddd;
    padding: 20px;
}

.card-title {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: black;
}

.card-subtitle {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 10px;
}


/* Component */
.component {
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.component-title {
    font-size: 1rem;
    color: black;
}

.component-subtitle {
    font-size: 1rem;
    font-weight: bold;
    color: gray;
}

/* modal */
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    width: 500px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-title {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}

.modal-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

/* Modal */
 .modal {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
}
/*
.modal-content {
    width: 80%;
    height: 80%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
} */

/* Container */
.container {
    width: 100%;
    max-height: 100vh;
    overflow-y: scroll;
}

.inner-container {
    padding: 10px;
}

/* Layout */
.row {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    /* justify-content: space-between; */
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.flex {
    display: flex;
}

.alignTop {
    align-items: flex-start;
}

.alignBottom {
    align-items: flex-end;
}

.alignCenter {
    align-items: center;
}

.justifyLeft {
    justify-content: left;
}



.justifyRight {
    justify-content: right;
}

.center {
    justify-content: center;
    align-items: center;
}

.justifySplit {
    justify-content: space-between;
}

/* Text */
.textCenter {
    text-align: center;
}

.textLeft {
    text-align: left;
}

.textRight {
    text-align: right;
}


.button-container {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 10px;
    gap: 10;
}

/* button */
.backgroundLess {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.primary-button {
    background-color: #1E90FF;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    min-width: 200px;
}

.primary-button:hover {
    background-color: #1873cc;
}

.secondary-button {
    background-color: #fff;
    color: #1E90FF;
    border: 2px solid #1E90FF;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 0.7rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.secondary-button:hover {
    background-color: #1E90FF;
    color: #fff;
}

/* disable */
.text-input:disabled {
    background-color: #f5f7f9;
    color: black;
    cursor: not-allowed;
}


.delete-button {
    background-color: transparent;
    color: red;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 0.7rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 2px solid red;
}

.delete-button:hover {
    background-color: red;
    color: #fff;
}

.cursor-pointer {
    cursor: pointer;
}

/* Input */

.input-label {
    font-size: 0.8rem;
    margin-bottom: 5px;
    color: black;
}

.input-container {
    width: 100%;
    margin-bottom: 20px;
}

.text-input {
    width: 100%;
    /* height: 40px; */
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 0.8rem;
    font-weight: 400;
}

.text-input:focus {
    outline: none;
    border: 1px solid #1E90FF;
}

.text-input::placeholder {
    color: #ccc;
}

.textarea-input {
    width: 100%;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 0.8rem;
    font-weight: 400;
    resize: none;
}



/* Select */
.select-input {
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 0.9rem;
    font-weight: 400;
    cursor: pointer;
    appearance: none;
}

.select-input:focus {
    outline: none;
    border: 1px solid #1E90FF;
}

.select-input::placeholder {
    color: #ccc;
}



/* recording */

.recording {
    width: 100px;
    height: 100px;
    background-color: #1E90FF;
    border-radius: 50%;
    display: flex;
}

/* animation recording */

.recording .circle {
    width: 80px;
    height: 80px;
    background-color: #fff;
    border-radius: 50%;
    margin: auto;
    animation: pulse 1s infinite;
}

.recording .circle .dot {
    width: 20px;
    height: 20px;
    background-color: #1E90FF;
    border-radius: 50%;
    margin: auto;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(30, 144, 255, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(30, 144, 255, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(30, 144, 255, 0);
    }
}

progress[value] {
    /* Reset the default appearance */

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    margin-top: 10px;
    height: 15px;
    border-radius: 10px;
}

progress[value]::-webkit-progress-value {
    border-radius: 10px;
    background-color: #1E90FF;
}

progress::-webkit-progress-bar {
    /* style rules */
    border-radius: 10px;
    background-color: #ccc;
}

/* File */
.file-input-container {
    transition: all 0.2s ease-in-out;
    width: 100%;
    background-color: white;
    padding: 20px 40px;
    border-radius: 20;
    border-style: dashed;
    border: 1px dashed gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.file-input-container.hover {
    border: 2px solid var(--primary);
    background-color: #f5f7f9;
}